<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="row align-items-center">
          <div class="col-lg-6 col-9 text-left">
            <h2>{{getTrans('messages.profile_details')}}</h2>
          </div>
          <div class="col-lg-6 col-3 text-right">
            <CircleImage></CircleImage>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12 form-group">
            <h3>{{getTrans('messages.name')}}</h3>
            <base-input disabled></base-input>
          </div>
          <div class="col-lg-4 col-12 form-group">
            <h3>{{getTrans('messages.image')}}</h3>
            <dropzone-file-upload
              v-model="selectedImage"
            ></dropzone-file-upload>
            <el-button
              class="btn-primary btn-block"
              outline
              type="primary"
              @click="submitUpload"
              method="post"
              >{{getTrans('messages.upload_image')}}</el-button
            >
          </div>
          <div class="col-lg-2 col-12 text-center">
            <img
              src="https://testapp.divatraffic.com/img/diva_avatar.png"
            />
            <base-input
              type="file"
              hidden="hidden"
              accept="image/*"
            ></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <el-tabs stretch v-model="activeTab">
              <el-tab-pane label="Profile info" name="ProfileInfo">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <h3>{{getTrans('messages.profile_info')}}</h3>
                    <p>{{getTrans('profile_info_sub')}}</p>
                  </div>
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 form-group">
                        <card>
                          <div class="col-12 m-2 inline-radio-container">
                            <p><strong>{{getTrans('messages.gender')}}:</strong></p>
                            <base-radio
                              v-model="genderradio"
                              name="radio0"
                              class="m-2"
                            >
                              {{getTrans('messages.female')}}
                            </base-radio>
                            <base-radio
                              v-model="genderradio"
                              name="radio1"
                              class="m-2"
                            >
                              {{getTrans('messages.male')}}
                            </base-radio>
                            <base-radio
                              v-model="genderradio"
                              name="radio2"
                              class="m-2"
                            >
                              {{getTrans('messages.trans')}}
                            </base-radio>
                            <base-radio
                              v-model="genderradio"
                              name="radio3"
                              class="m-2"
                            >
                              {{getTrans('messages.couple')}}
                            </base-radio>
                          </div>
                          <div class="col-12 m-2">
                            <p><strong>{{getTrans('messages.country')}}:</strong></p>
                            <el-select
                              clearable
                              filterable
                              type="primary"
                              v-model="selectedCountry"
                            >
                              <el-option
                                v-for="country in getCountries"
                                :key="country.id"
                                :value="country.id"
                                :label="country.name"
                              >
                              </el-option>
                            </el-select>
                          </div>
                          <div class="col-12 m-2">
                            <p><strong>{{getTrans('messages.age')}}:</strong></p>
                            <el-select
                              clearable
                              filterable
                              type="primary"
                              v-model="selectedAge"
                            >
                              <el-option
                                v-for="age in ageList"
                                :key="age.value"
                                :value="age.value"
                                :label="age.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                          <div class="col-12 m-2">
                            <p><strong>{{getTrans('messages.about_me')}}:</strong></p>
                            <form>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                            </form>
                          </div>
                          <div class="col-12">
                            <p><strong>{{getTrans('messages.guest_message')}}:</strong></p>
                            <form>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea2"
                                rows="3"
                              ></textarea>
                            </form>
                          </div>
                        </card>
                        <div class="col-12 text-center">
                          <el-button plain type="info" @click="exit"
                            >{{getTrans('messages.cancel')}}</el-button
                          >
                          <el-button type="primary">{{getTrans('messages.add')}}</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Room settings" name="RoomSettings">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <h3>{{getTrans('messages.room_settings')}}</h3>
                  </div>
                  <div class="col-12">
                    <div class="col-lg-4">
                      <span class="p-2">{{getTrans('messages.max_viewers')}}</span>
                      <el-tooltip
                        class="item"
                        effect="light"
                        content="When Max Viewers is reached we will stop sending traffic until the viewer count is below this number again. Depending on the camsite and the targeting of the package, there is not a guarantee to reach this number, but to work as an upper limit"
                        placement="bottom"
                      >
                        <i class="fas fa-question-circle"></i>
                      </el-tooltip>
                    </div>
                    <div class="col-lg-8">
                      <el-slider
                        v-model="slidervalue"
                        :min="10"
                        :max="1000"
                        show-input
                      >
                      </el-slider>
                    </div>
                  </div>
                  <div class="col-12 text-center p-2">
                    <el-button plain type="info" @click="exit"
                      >{{getTrans('messages.cancel')}}</el-button
                    >
                    <el-button type="primary">{{getTrans('messages.add')}}</el-button>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput";
import BaseRadio from "@/components/Inputs/BaseRadio";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import CircleImage from "@/components/Images/CircleImage.vue";
import {
  ElButton,
  ElOption,
  ElSelect,
  ElSlider,
  ElTabs,
  ElTabPane,
  ElTooltip,
} from "element-plus";
import { mapGetters } from "vuex";
export default {
  components: {
    BaseInput,
    BaseRadio,
    DropzoneFileUpload,
    [ElButton.name]: ElButton,
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    [ElSlider.name]: ElSlider,
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    [ElTooltip.name]: ElTooltip,
    CircleImage
  },
  data() {
    return {
      genderradio: "1",
      activeTab: "first",
      slidervalue: 0,
      selectedAge: 18,
      selectedCountry: null,
      selectedImage: []
    };
  },
  computed: {
    ageList() {
      var ages = [];
      for (var i = 18; i < 100; i++) {
        ages.push({ value: i });
      }
      return ages;
    },
    ...mapGetters("Country", ["getCountries"])
  },
  methods: {
    exit() {
      this.$router.push({ name: "Profiles" });
    },
    submitUpload() {
      this.$refs.upload.submit();
    }
  },
  mounted() {
    this.$store.dispatch("Country/get");
  }
};
</script>
<style scoped>
.inline-radio-container div {
  display: inline-flex !important;
}
</style>
